<template>
  <div class="success">
    <h1>忘记密码</h1>
    <span>FORGET PASSWORD</span>
    <img
      src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-8b817809-2684-40ed-aa99-9bdb97d47377/5105e7a2-0d88-4fd8-8327-1c51bd39868d.png"
      alt=""
    />
    <p>密码找回成功，点击下方返回登录！</p>
    <h2 class="timer">自动返回登录还剩 ({{ time }}) 秒</h2>
    <el-row>
      <el-button @click="back" class="back" type="primary">返回登陆</el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 初始化0
      time: 0,
    };
  },
  methods: {
    back() {
      this.$parent.found = false;
      this.$parent.password = true;
    },
    //   封装一个方法
    countDown() {
      let THIS = this;
      THIS.time--;
    },
  },
  mounted() {
    let THIS = this;
    // 把time改为5
    THIS.time = 5;
    // 设定一个定时器，每一秒调用一次coundDown方法，time-1
    let timer = setInterval(THIS.countDown, 1000);
    this.$once("hook:beforeDestory", () => {
      clearInterval(timer);
      timer = null;
    });
    console.log(timer);
  },
  watch: {
    //   监听time的值
    time: function (newVal) {
      console.log(newVal);
      if (newVal == 0) {
        this.$parent.found = false;
        this.$parent.password = true;
      }
    },
  },
  beforeDestroy() {
    // console.log("组件销毁期");
  },
};
</script>

<style scoped>
.success {
  width: 400px;
  height: 403px;
  background: #fff;
  border-radius: 12px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.success h1 {
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
  margin: 50px 0 0 56px;
  /* line-height: 24px; */
}
.success span {
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000000;
  margin: 3px 0 0 56px;
  /* line-height: 12px; */
}
.success img {
  width: 78px;
  display: block;
  margin: 41px auto 14px;
}
.success p {
  display: block;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: center;
  color: #34b75e;
  line-height: 14px;
}
.back {
  width: 288px;
  height: 40px;
  margin: 11px 0 0 56px;
}
.success h2 {
  text-align: center;
  margin-top: 60px;
  font-size: 14px;
  font-weight: 400;
  color: #409eff;
}
</style>
