<template>
  <div>
    <div class="login_password" v-if="password">
      <div class="password_context">
        <h1>忘记密码</h1>
        <h2>FORGET PASSWORD</h2>
        <el-form
          :model="fogetForm"
          :rules="fogetFormRul"
          ref="fogetFormRel"
          class="fogetbox"
        >
          <el-form-item prop="name">
            <el-input
              class="ipt"
              v-model="fogetForm.name"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="tel">
            <el-input
              class="ipt"
              v-model="fogetForm.tel"
              placeholder="请输入手机号/账号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="new_password">
            <el-input
              class="ipt"
              type="newpassword"
              v-model="fogetForm.new_password"
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="confirm_password">
            <el-input
              class="ipt"
              type="confirmpassword"
              v-model="fogetForm.confirm_password"
              placeholder="请确认新密码"
            ></el-input> </el-form-item
          ><el-row>
            <el-button @click="submitForm('ruleForm')" class="sure"
              >确认</el-button
            >
          </el-row>
          <el-row>
            <el-button @click="submitBack()" class="back">返回登录</el-button>
          </el-row>
        </el-form>
      </div>
    </div>
    <transition name="found" mode="out-in">
      <Found v-if="found" />
    </transition>
  </div>
</template>

<script>
import Found from "./foundsuccess.vue";
export default {
  data() {
    return {
      password: true,
      found: false,
      //表单请求数据
      fogetForm: {
        tel: "",
        name: "",
        new_password: "",
        confirm_password: "",
      },
      //表单验证规则
      fogetFormRul: {
        name: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
          {
            min: 8,
            max: 11,
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入账号/手机号",
            trigger: "blur",
          },
          {
            min: 8,
            max: 11,
            message: "长度在8-11个字符",
            trigger: "blur",
          },
        ],
        new_password: [
          {
            required: true,
            message: "新密码",
            trigger: "blur",
          },
          {
            min: 5,
            max: 10,
            message: "长度在 5 到 10 个字符",
            trigger: "blur",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: "确认新密码",
            trigger: "blur",
          },
          {
            min: 5,
            max: 10,
            message: "长度在 5 到 10 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    Found,
  },
  created() {},
  methods: {
    submitBack() {
      this.$parent.loginShow = true;
      this.$parent.wordShow = false;
    },
    submitForm() {
      if (this.fogetForm.new_password === this.fogetForm.confirm_password) {
        this.axios
          .post("/api/member/forget_password", this.fogetForm)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.found = true;
              this.password = false;
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          });
      } else {
        this.$message({
          type: "error",
          message: "两次新密码不一致",
        });
      }
      // this.password = false;
      // this.found = true;
    },
  },
};
</script>

<style scoped="scoped">
.login_password {
  width: 400px;
  height: 503px;
  display: flex;
  margin: 0 auto;
  position: absolute;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #ffffff;
}

.password_context h1 {
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
  margin: 40px 0 0 56px;
}

.password_context h2 {
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000000;
  margin: 0 0 37px 56px;
}
.ipt {
  width: 288px;
  height: 40px;
  margin-left: 56px;
  margin-bottom: 18px;
  border-radius: 4px;
}
div/deep/.el-form-item__error {
  z-index: 99;
  position: absolute;
  top: 65%;
  left: 55px !important;
}
.sure {
  width: 288px;
  height: 40px;
  line-height: 11px !important;
  background: linear-gradient(90deg, #1131ff 0%, #005dff 100%);
  opacity: 1;
  border-radius: 4px;
  margin: 14px 0 10px 56px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #ffffff;
}
.back {
  width: 288px;
  height: 40px;
  border: 1px solid #0f38ff;
  margin-left: 56px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #0f38ff;
}
div/deep/.el-form-item {
  margin-bottom: 0;
}
.found-enter-active,
.found-leave-active {
  /* 进入 / 离开 过度生效状态 */
  transition: all 0.5s;
}
.found-enter,
.found-leave-to {
  /* 进入状态 & 结束状态 opacity为0 */
  opacity: 0;
}
</style>
