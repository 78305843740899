<template>
  <div class="logins">
    <transition name="login" mode="out-in">
      <Login v-if="loginShow" />
      <Password v-if="wordShow" />
    </transition>
  </div>
</template>

<script>
import Password from "../components/Login/password.vue";
import Login from "../components/Login/login.vue";
export default {
  components: {
    Password,
    Login,
  },
  data() {
    return {
      loginShow: true,
      wordShow: false,
    };
  },
};
</script>

<style scoped="scoped">
.logins {
  height: 100%;
  width: 100%;
  background: url("../assets/images/background.png") no-repeat center;
  overflow: hidden;
}
.login-enter {
  /*过度进入时初始状态*/
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(300px);
}
.login-enter-to,
.login-leave {
  /*进入过度结束状态 & 过度结束离开初始状态*/
  opacity: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.login-leave-to {
  /*过度结束状态*/
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-300px);
}
.login-enter-active,
.login-leave-active {
  /*过度动效*/
  transition: all 0.5s ease;
}
</style>
