<template>
  <div class="login">
    <div
      class="login_info"
      v-loading="loading"
      element-loading-text="登陆中···"
      element-loading-background="rgba(0, 0, 0, 0.4)"
      element-loading-spinner="el-icon-loading"
    >
      <div class="login_context">
        <h1>欢迎登录高企邦智慧云平台</h1>
        <h2>Login System</h2>
        <el-form :model="loginForm" :rules="loginFormRul" ref="loginFormRel" class="loginbox">
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" placeholder="请输入账号" class="ipt"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="loginForm.password"
              placeholder="请输入密码"
              class="ipt"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-row :span="24">
              <el-col :span="18">
                <el-input
                  class="input"
                  v-model="loginForm.code"
                  auto-complete="off"
                  placeholder="请输入验证码"
                  size
                  @keyup.enter.native="submitForm('loginForm')"
                ></el-input>
              </el-col>
              <el-col :span="6" class="loginCode">
                <div class="login-code" @click="refreshCode">
                  <s-identify :identifyCode="identifyCode"></s-identify>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
          <h3 @click="wordBtn()" class="password">忘记密码?</h3>
          <el-form-item>
            <el-button type="primary" @click="submitForm()">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import SIdentify from "../SIdentify.vue";
import { mapState } from "vuex";
export default {
  components: {
    SIdentify,
  },
  data () {
    const validateCode = (rule, value, callback) => {
      if (this.identifyCode !== value) {
        this.loginForm.code = "";
        this.refreshCode();
        callback(new Error("请输入正确的验证码"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      //表单请求数据
      loginForm: {
        username: "",
        password: "",
        code: "",
      },
      identifyCodes: "1234567890",
      identifyCode: "",
      //表单验证规则
      loginFormRul: {
        username: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            message: "最小为6位数",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
          {
            validator: validateCode,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted () {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  created () {
    this.refreshCode();
    this.axios.post("/api/member/has_login").then((res) => {
      console.log(res.errcode, "res");
      if (res.code == 1) {
        this.$router.push("/index");
      } else if (res.errcode == 1002) {
        localStorage.removeItem("myname", "token");
        this.$router.push("/");
      }
    });
  },
  computed: {
    ...mapState(["resetUserForm", "userinfo"]),
  },
  methods: {
    wordBtn () {
      this.$parent.loginShow = false;
      this.$parent.wordShow = true;
    },
    submitForm () {
      this.loading = true;
      this.$refs.loginFormRel.validate((vaild) => {
        if (vaild) {
          //http://www.gaoqibang.com:8081/api/member/login
          this.axios
            .post("/api/member/login", {
              tel: this.loginForm.username,
              password: this.loginForm.password,
            })
            .then((res) => {
              localStorage.setItem("token", res.data.jwt_token);
              this.axios.get('/api/member/get_privilige').then((data) => {
                console.log(data, '/api/member/get_privilige')
                localStorage.setItem('privilige', JSON.stringify(data))
              })
              console.log(res.data.jwt_token);
              this.axios
                .get("/api/member/view_self", {
                  params: {
                    token: res.data.jwt_token,
                  },
                })
                .then((res) => {
                  console.log(res);
                  localStorage.setItem("myname", res.data.true_name);
                  localStorage.setItem('myself_id', res.data.id)
                });
              this.loading = false;
              this.$message.success(res.message);
              this.$router.push("/index");
            })
            .catch((res) => {
              this.$message.error("请输入正确的账号密码！");
              this.loading = false;
            });
        } else {
          this.$message.error("请输入正确的验证码！");
          this.loading = false;
        }
      });
    },
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode () {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
  },
};
</script>

<style scoped="scoped">
.login_info {
  width: 400px;
  height: 403px;
  background: #fff;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
}
.login_context h1 {
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  margin-top: 40px;
  text-align: center;
  color: #000;
}
.login >>> .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
}
.loginbox >>> .ipt {
  width: 288px;
  height: 40px;
  margin-left: 55px;
  background: #fff !important;
  margin-bottom: 10px;
}
.input {
  width: 288px;
  height: 40px;
  margin-left: 55px;
  position: relative;
}
.el-col-18 {
  width: 65%;
}
.login_context h2 {
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000;
  margin-left: 55px;
  margin-bottom: 37px;
}
.password {
  height: 20px;
  font-size: 10px;
  color: #1131ff;
  padding: 3px;
  margin-left: 270px;
  position: absolute;
  bottom: 85px;
  cursor: pointer;
}
.password:hover {
  color: #307cff;
}
.el-button {
  width: 288px;
  height: 40px;
  margin: 10px 0 0 55px;
  line-height: 7px !important;
  background: linear-gradient(90deg, #1131ff 0%, #005dff 100%);
  opacity: 1;
  border-radius: 3px;
}
.el-button:hover {
  background: linear-gradient(90deg, #2945fc 0%, #307cff 100%);
}
div/deep/.el-form-item__error {
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 140%;
  left: 55px !important;
  z-index: 9999;
}
/*验证码样式*/
.s-canvas >>> canvas {
  width: 109px;
  height: 38px;
  border-radius: 3px;
}
.s-canvas {
  position: absolute;
  top: 0;
  right: 58px;
}
.login-code {
  cursor: pointer;
}
div/deep/.el-loading-spinner i {
  color: #fff;
  font-size: 40px;
}
div/deep/ .el-loading-text {
  color: #fff;
  /* font-size: 16px; */
}
div/deep/.el-form-item__content {
  margin-bottom: 10px;
}
</style>
